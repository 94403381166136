.welcome-page {
  display: flex;
  align-items: center; /* Центрує по вертикалі */
  justify-content: center; /* Центрує по горизонталі */
  height: 100vh; /* Встановлює висоту блока на всю висоту видимого екрану */

  img {
    max-width:200px ; /* Робить картинку адаптивною по ширині */
    max-height: auto; /* Робить картинку адаптивною по висоті */
    width: auto; /* Забезпечує пропорції при зміні ширини */
    height: auto; /* Забезпечує пропорції при зміні висоти */
  }
}